import * as React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as testimonialStyles from "./testimonialSlider.module.css"
import { Carousel } from "react-bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"

const TestimonialSlider = ({ splash, pageSlug }) => {
  const TestimonialsWrapper = () => (
    <StaticQuery
      query={graphql`
        query {
          allMysqlTestimonials {
            nodes {
              testimonial_company_name
              test_image_id
              testimonial_month_and_year
              testimonial_name
              testimonial_quote_long
              testimonial_title
            }
          }
        }
      `}
      render={data => {
        const posts = data.allMysqlTestimonials.nodes

        return (
          <>
            <Carousel slide indicators={true}>
              {posts.map((post, i) => (
                <Carousel.Item key={i} interval={3000}>
                  {/* <StaticImage
                       className="d-block w-100"
                       placeholder="blurred"
                       src="../images/slides/main-page-1.jpg"
                       alt="About"
                       style={{ display: "block" }}
                     /> */}
                  <div className="row justify-content-center p-3">
                    <div className="col-xs-1 text-center">
                      {/* <StaticImage
                        className=""
                        placeholder="blurred"
                        src="../images/khan.png"
                        alt="About"
                      /> */}

                      <svg
                        className="bd-placeholder-img rounded-circle"
                        width="140"
                        height="140"
                        role="img"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                      >
                        <rect width="100%" height="100%" fill="#2d343f" />
                        <text
                          x="50%"
                          y="50%"
                          dominant-baseline="middle"
                          text-anchor="middle"
                          fontSize="3em"
                          fill="#fff"
                        >
                          {post.testimonial_name.match(/\b(\w)/g)[0]}
                          {post.testimonial_name.match(/\b(\w)/g)[1]
                            ? post.testimonial_name.match(/\b(\w)/g)[1]
                            : null}
                        </text>
                      </svg>
                      <div className={testimonialStyles.star}>
                        <i className="bi bi-star-fill p-1"></i>
                        <i className="bi bi-star-fill p-1"></i>
                        <i className="bi bi-star-fill p-1"></i>
                        <i className="bi bi-star-fill p-1"></i>
                        <i className="bi bi-star-fill p-1"></i>
                      </div>

                      {/* <h2>Heading</h2> */}
                      <p className={testimonialStyles.testimonialsText}>
                        {post.testimonial_quote_long}
                      </p>
                      <div>
                        <p className={testimonialStyles.testimonialsText}>
                          <span
                            className={testimonialStyles.testimonialsTextName}
                          >
                            {post.testimonial_name}
                          </span>
                          {post.testimonial_title &&
                            ", " + post.testimonial_title}

                          {post.testimonial_company_name &&
                            ", " + post.testimonial_company_name}
                        </p>
                        <p className={testimonialStyles.testimonialsText}>
                          {post.testimonial_month_and_year}
                        </p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </>
        )
      }}
    />
  )

  return <TestimonialsWrapper />
}

export default TestimonialSlider
