import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import TestimonialSlider from "../components/testimonialSlider"

/*

Testimonial data:

- logo / generate letter with color
- rating (5 stars default)
- text
- Name
- Position
- Month / year

How to:

- circle and crop image? generating letter! / later enhancement


*/

const Testimonials = () => (
  <Layout jobPage={false} splashPage={false} pageTitle="TESTIMONIALS">
    <Seo title="Vacancies" />
    <TestimonialSlider />
  </Layout>
)

export default Testimonials
